import React, { useState } from "react";
import "./FileUploadModal.scss"; // Import the CSS file

interface FileUploadModalProps {
    show: boolean;
    onClose: () => void;
    onFileUpload?: (files: any) => void;
}

const FileUploadModal = ({ show, onClose, onFileUpload }: FileUploadModalProps) => {
    const [selectedFiles, setSelectedFiles] = useState([]);

    if (!show) {
        return null;
    }

    const handleFileSelection = (e) => {
        setSelectedFiles(e.target.files);
    };

    const handleFileUpload = async () => {
        for (let i = 0; i < selectedFiles.length; i++) {
            const file = selectedFiles[i];
            const formData = new FormData();
            formData.append("file", file);

            try {
                const response = await fetch("/api/upload/", {
                    method: "POST",
                    body: formData,
                });

                if (response.ok) {
                    const result = await response.json();
                    console.log("File uploaded successfully:", result);
                } else {
                    console.error("File upload failed for", file.name);
                }
            } catch (error) {
                console.error("Error uploading file:", file.name, error);
            }
        }
        onClose(); // Close the modal after all uploads are complete
    };

    return (
        <div className="modal">
            <div className="modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <h2>Upload Files</h2>
                <input type="file" onChange={handleFileSelection} multiple/>
                <button onClick={handleFileUpload}>Upload</button>
            </div>
        </div>
    );
};

export default FileUploadModal;
