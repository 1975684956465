import React, { useState } from 'react';
import './Home.scss';
import useWebSocket from "./../../hooks/useWebSocket";
import LeftContainer from "../LeftContainer";
import LinksContainer from "../LinksContainer";
import RightContainer from "../RightContainer";
import ChatContainer from "../ChatContainer";
import ControlRow from "../../components/ControlRow";
import SpeechModal from "../../components/SpeechModal";
import FileUploadModal from "../../components/FileUploadModal";


const Home = () => {
    const queryParams = new URLSearchParams(window.location.search);
    let port = parseInt(queryParams.get("port"), 10);

    // Determine if we're running locally
    const isLocalhost =
        window.location.hostname === "localhost" ||
        window.location.hostname === "127.0.0.1";

    // If the port is NaN or not specified, set it to 443 for wss:// connections
    if (isNaN(port) && !isLocalhost) {
        port = 443; // Default to port 443 for secure connections
    }
    // Use ws:// for localhost, and wss:// for anything else
    const wsUrl = isLocalhost
        ? `wss://localhost/ws/`  // WebSocket for local development
        : `wss://${window.location.hostname}/ws/`;  // Secure WebSocket for production


    const {
        messages,
        sendMessage,
        objective,
        problem,
        sources,
        links,
        resetChat,
        chatId,
    } = useWebSocket(wsUrl);

    const [isModalOpen, setModalOpen] = useState(false);
    const [password, setPassword] = useState("");
    const [isAuthenticated, setAuthenticated] = useState(false);
    const correctPassword = "12*ç56";

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const speechMode = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const handleLogin = () => {
        if (password === correctPassword) {
            setAuthenticated(true);
        } else {
            alert("Incorrect password");
        }
    };

    const [isFileUploadModalOpen, setFileUploadModalOpen] = useState(false);

    const openFileUploadModal = () => {
        setFileUploadModalOpen(true);
    };

    const closeFileUploadModal = () => {
        setFileUploadModalOpen(false);
    };

    const handleFileUpload = (files) => {
        console.log("Files uploaded:", files);
        setFileUploadModalOpen(false);
    };

    if (!isAuthenticated) {
        return (
            <div className="password-container">
                <h2>Enter Password</h2>
                <input
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                />
                <button onClick={handleLogin}>Submit</button>
            </div>
        );
    }

    return (
        <div className="App">
            <div id="state_machine-container">
                <LeftContainer objective={objective} problem={problem}/>
                <LinksContainer links={links}/>
                <RightContainer sources={sources}/>
            </div>
            <ChatContainer
                messages={messages}
                show={isModalOpen}
                sendMessage={sendMessage}
            />
            <ControlRow
                resetChat={resetChat}
                speechMode={speechMode}
                uploadFile={openFileUploadModal} // Update to open the file upload modal
            />
            <SpeechModal
                show={isModalOpen}
                onClose={closeModal}
                messages={messages}
                sendMessage={sendMessage}
                chatId={chatId}
            >
                <div className="modal-header">Speech Mode</div>
                <div className="modal-body">
                    <p>Speech mode is now active.</p>
                </div>
            </SpeechModal>
            <FileUploadModal
                show={isFileUploadModalOpen}
                onClose={closeFileUploadModal}
                onFileUpload={handleFileUpload}
            />
        </div>
    );
}

export default Home;
