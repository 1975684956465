import React from 'react';
import './BaseLayout.scss';
import { Outlet } from "react-router-dom";
import Header from "../../components/Header";


const BaseLayout = () => {
    return (
        <>
            <Header/>
            <Outlet/>
            {/*<Footer/>*/}
        </>
    )
}

export default BaseLayout;
