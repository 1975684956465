import React from "react";
import { MdLink } from "react-icons/md";

const LinkItem = ({ link }) => {
    return (
        <div style={styles.container}>
            <MdLink size={20} color="#007bff"/>
            <p style={styles.text} dangerouslySetInnerHTML={{ __html: link }}/>
        </div>
    );
};

const styles = {
    container: {
        display: "flex",
        alignItems: "center",
        padding: "10px 0",
        borderBottom: "1px solid #ccc",
        minHeight: "50px",
        width: "100%",
    },
    link: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        textDecoration: "none",
        color: "inherit",
    },
    text: {
        marginLeft: "10px",
        fontSize: "14px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        maxWidth: "calc(100% - 31px)", // Ensuring the text does not overlap the icon
    },
};

export default LinkItem;

