import React from 'react';
import './styles/App.scss';
import { Route, Routes } from 'react-router-dom';
import BaseLayout from './containers/BaseLayout';
import Home from './containers/Home';

const App = () => {
    return (
        <Routes>
            <Route path="/" element={<BaseLayout/>}>
                <Route index element={<Home/>}/>
            </Route>
        </Routes>
    );
};

export default App;
