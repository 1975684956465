import React, { useCallback, useEffect, useRef, useState } from "react";
import "./SpeechModal.scss";
import "../../App.css"; // Assuming the reset-button style is here
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import VolumeRipple from "../VolumeRipple";

const SpeechModal = ({
    show,
    onClose,
    children,
    messages,
    sendMessage,
    chatId,
}) => {
    const [text, setText] = useState("");
    const textRef = useRef(text);
    const [lastMessage, setLastMessage] = useState(null);
    const playerRef = useRef(null);
    const [volume, setVolume] = useState(0);
    const microphoneRef = useRef(null);
    const recognizerRef = useRef(null);
    const timeoutRef = useRef(null);
    const isSynthesizingRef = useRef(false);
    const isPlayingAudioRef = useRef(false);
    const isLoadingAudioRef = useRef(true);
    const isUsingSpeechModeRef = useRef(false);
    const language = "en-US";
    const languageRef = useRef(language);


    useEffect(() => {
        languageRef.current = language;
    }, [language]);

    useEffect(() => {
        isUsingSpeechModeRef.current = show;
    }, [show]);

    useEffect(() => {
        textRef.current = text;
    }, [text]);

    const playNotificationSound = () => {
        const audio = new Audio("/notification.mp3");
        audio.volume = 0.5;
        audio.oncanplaythrough = () => {
            audio.play().catch((error) => {
                console.error("Error playing audio:", error);
            });
        };
        audio.onerror = (e) => {
            console.error("Error loading audio file:", e);
        };
    };

    sdk.Recognizer.enableTelemetry(false);

    const stopContinuousRecognition = useCallback(() => {
        if (recognizerRef.current) {
            recognizerRef.current.stopContinuousRecognitionAsync(
                () => {
                    console.log("Recognition stopped successfully.");
                },
                (err) => {
                    console.error(`Failed to stop continuous recognition: ${err}`);
                }
            );
        }
    }, []);

    const handleSendMessage = useCallback(() => {
        let textNow = textRef.current;
        console.log("Sending message: " + textNow);

        if (isPlayingAudioRef.current && isLoadingAudioRef.current) {
            setText("");
            return;
        }

        if (textNow.trim() !== "") {
            sendMessage(textNow, isUsingSpeechModeRef.current);
            stopContinuousRecognition();
            isLoadingAudioRef.current = true;
            isPlayingAudioRef.current = true;
            setText("");
        }
    }, [sendMessage, stopContinuousRecognition]);


    const resetTimeout = useCallback(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            if (textRef.current.trim() === "") {
                return;
            }
            console.log("Calling handleSendMessage  : " + textRef.current);
            handleSendMessage();
        }, 2000);
    }, [handleSendMessage]);


    const startContinuousRecognition = useCallback(() => {
        if (!isUsingSpeechModeRef.current) {
            console.log("Modal is not open, recognition will not start.");
            return;
        }

        console.log("Attempting to start continuous recognition...");

        if (isSynthesizingRef.current) {
            console.log("Recognition not started: currently synthesizing.");
            return;
        }

        if (isPlayingAudioRef.current) {
            console.log("Recognition not started: audio is currently playing.");
            return;
        }

        try {
            playNotificationSound();

            const speechConfig = sdk.SpeechConfig.fromSubscription(
                process.env.REACT_APP_SPEECH_KEY,
                process.env.REACT_APP_SPEECH_REGION
            );
            if (!speechConfig) {
                //console.error("Speech configuration could not be created.");
                return;
            }

            speechConfig.speechRecognitionLanguage = "en-US";

            const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
            if (!audioConfig) {
                //console.error("Audio configuration could not be created.");
                return;
            }

            const recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
            recognizerRef.current = recognizer;

            recognizer.recognizing = (
                s,
                e) => {
                console.log(`RECOGNIZING: Text=${e.result.text}`);
                resetTimeout();
            };

            recognizer.recognized = (
                s,
                e) => {
                if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
                    console.log(`RECOGNIZED: Text=${e.result.text}`);
                    let oldText = textRef.current;
                    if (e.result.text !== "Play.") {
                        setText(oldText + " " + e.result.text);
                    }
                    resetTimeout();
                } else if (e.result.reason === sdk.ResultReason.NoMatch) {
                    console.log("NOMATCH: Speech could not be recognized.");
                }
            };

            recognizer.canceled = (
                s,
                e) => {
                console.log(`CANCELED: Reason=${e.reason}`);
                if (e.reason === sdk.CancellationReason.Error) {
                    console.error(`CANCELED: ErrorCode=${e.errorCode}`);
                    console.error(`CANCELED: ErrorDetails=${e.errorDetails}`);
                    console.error(
                        "CANCELED: Did you set the speech resource key and region values?"
                    );
                }
                recognizer.stopContinuousRecognitionAsync(() => {
                    console.log("Recognition stopped due to cancellation.");
                });
            };

            recognizer.sessionStopped = (
                s,
                e) => {
                console.log("Session stopped.");
                recognizer.stopContinuousRecognitionAsync(() => {
                    console.log("Recognition stopped due to session end.");
                });
            };

            recognizer.startContinuousRecognitionAsync(
                () => {
                    console.log("Continuous recognition started successfully.");
                },
                (err) => {
                    console.error(`Failed to start continuous recognition: ${err}`);
                }
            );
        } catch (error) {
            console.error(`Error in startContinuousRecognition: ${error}`);
        }
    }, [isSynthesizingRef, isPlayingAudioRef, resetTimeout]);


    const handleButtonClick = useCallback((textToSynthesize) => {
        const speechConfig = sdk.SpeechConfig.fromSubscription(
            process.env.REACT_APP_SPEECH_KEY,
            process.env.REACT_APP_SPEECH_REGION
        );
        speechConfig.speechSynthesisVoiceName = "en-US-AvaMultilingualNeural";

        const player = new sdk.SpeakerAudioDestination();
        const audioConfig = sdk.AudioConfig.fromSpeakerOutput(player);
        const synthesizer = new sdk.SpeechSynthesizer(speechConfig, audioConfig);

        playerRef.current = player;
        isSynthesizingRef.current = true;

        player.onAudioStart = () => {
            console.log("Audio started.");
            isLoadingAudioRef.current = false;
            isPlayingAudioRef.current = true;
        };

        player.onAudioEnd = () => {
            console.log("Audio ended.");
            isPlayingAudioRef.current = false;
            isLoadingAudioRef.current = true;

            // Wait for half a second before starting recognition
            setTimeout(() => {
                startContinuousRecognition();
            }, 500);
        };

        synthesizer.speakTextAsync(
            textToSynthesize,
            (result) => {
                if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
                    console.log("Synthesis finished.");
                } else {
                    console.error(
                        "Speech synthesis canceled, " +
                        result.errorDetails +
                        "\nDid you set the speech resource key and region values?"
                    );
                }
                synthesizer.close();
                isSynthesizingRef.current = false;
            },
            (err) => {
                console.trace("err - " + err);
                synthesizer.close();
                isSynthesizingRef.current = false;
            }
        );

        console.log("Now synthesizing to default speaker.");
    }, [startContinuousRecognition]);


    const handleInterrupt = () => {
        if (playerRef.current) {
            playerRef.current.pause();
            isPlayingAudioRef.current = false;
            isLoadingAudioRef.current = false;
            stopContinuousRecognition();
            startContinuousRecognition();
        }
    };

    const startRecognitionRef = useRef(startContinuousRecognition);
    const stopRecognitionRef = useRef(stopContinuousRecognition);

    useEffect(() => {
        startRecognitionRef.current = startContinuousRecognition;
        stopRecognitionRef.current = stopContinuousRecognition;
    }, [startContinuousRecognition, stopContinuousRecognition]);

    useEffect(() => {
        if (show) {
            startRecognitionRef.current();
        } else {
            stopRecognitionRef.current();
            playerRef.current?.pause();
            isPlayingAudioRef.current = false;
        }
        return () => stopRecognitionRef.current();
    }, [show]);

    useEffect(() => {
        if (messages.length > 0 && show) {
            const lastMsg = messages[messages.length - 1];
            if (lastMsg !== lastMessage && !lastMsg.isUser) {
                setLastMessage(lastMsg);
                if (
                    lastMsg.finished &&
                    lastMsg.speechMode &&
                    isLoadingAudioRef.current
                ) {
                    handleButtonClick(lastMsg.content);
                }
            }
        }
    }, [messages, lastMessage, show, handleButtonClick]);

    useEffect(() => {
        const handleSuccess = (stream: any) => {
            // @ts-ignore
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const source = audioContext.createMediaStreamSource(stream);

            const gainNode = audioContext.createGain();
            gainNode.gain.value = 0.5; // Set initial gain value (volume), you can adjust this value

            const analyser = audioContext.createAnalyser();
            analyser.fftSize = 256;

            source.connect(gainNode);
            gainNode.connect(analyser);

            const dataArray = new Uint8Array(analyser.frequencyBinCount);

            const getVolume = () => {
                analyser.getByteFrequencyData(dataArray);
                const volume = dataArray.reduce((
                    a,
                    b) => a + b, 0) / dataArray.length;
                setVolume(volume);
            };

            microphoneRef.current = setInterval(getVolume, 100);
        };

        if (show) {
            navigator.mediaDevices.getUserMedia({ audio: true }).then(handleSuccess);
        }

        return () => {
            if (microphoneRef.current) {
                clearInterval(microphoneRef.current);
            }
        };
    }, [show]);

    if (!show) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="modal-close" onClick={onClose}>
                    &times;
                </button>
                <VolumeRipple
                    volume={volume}
                    isBot={isPlayingAudioRef.current}
                    isLoading={isLoadingAudioRef.current}
                />
                <div>{text}</div>
                <button className="reset-button" onClick={handleInterrupt}>
                    Interrupt
                </button>
            </div>
        </div>
    );
};

export default SpeechModal;
