import { useEffect, useRef, useState } from "react";

function useWebSocket(url: string) {
    const [messages, setMessages] = useState([]);
    const [objective, setObjective] = useState("None");
    const [problem, setProblem] = useState("None");
    const [sources, setSources] = useState([]);
    const [links, setLinks] = useState([]);
    const chatIdRef = useRef(""); // Use useRef for chatId
    const regCountRef = useRef(0); // Use useRef for regCount
    const wsRef = useRef(null);

    const resetChat = () => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            wsRef.current.close();
        }
        setMessages([]);
        setObjective("None");
        setProblem("None");
        setSources([]);
        setLinks([]);
        chatIdRef.current = ""; // Reset chatId using useRef
        regCountRef.current = 0; // Reset regCountRef
    };

    useEffect(() => {
        const connectWebSocket = () => {
            console.log("Connecting to WebSocket at", url);
            wsRef.current = new WebSocket(url);

            wsRef.current.onopen = () => {
                setObjective("1. Goal setting and problem identification");
            };

            wsRef.current.onerror = (error) => {
            };

            wsRef.current.onclose = () => {
                setTimeout(connectWebSocket, 1000);
            };

            wsRef.current.onmessage = (event) => {
                handleMessage(JSON.parse(event.data));
            };
        };

        connectWebSocket();

        return () => {
            wsRef.current?.close();
        };
    }, [url]);


    const handleMessage = (data) => {
        if (data.chatid && data.chatid.trim() !== "") {
            //console.log("Updating chatId to:", data.chatid);
            chatIdRef.current = data.chatid; // Update chatId using useRef
        } else {
            console.warn("Received empty or missing chatId in data:", data);
        }

        switch (data.type) {
            case "update":
                // Check if data.state exists and is not null/undefined
                if (data.state && typeof data.state === 'object') {
                    const new_state = data.state;


                    if (new_state.objective) setObjective(new_state.objective);
                    if (new_state.problem) setProblem(new_state.problem);

                } else {
                    if (data.objective) setObjective(data.objective);
                    if (data.problem) setProblem(data.problem);
                }
                break;
            case "sources":
                setSources(data.sources);
                setLinks(data.links);
                break;
            case "start":
                setMessages((prevMessages) => [
                    ...prevMessages,
                    { content: "", isUser: false, finished: false },
                ]);
                break;
            case "content":
                setMessages((prevMessages) => [
                    ...prevMessages.slice(0, -1),
                    {
                        ...prevMessages[prevMessages.length - 1],
                        content:
                            prevMessages[prevMessages.length - 1].content + data.content,
                    },
                ]);
                break;
            case "end":

                setMessages((prevMessages) => [
                    ...prevMessages.slice(0, -1),
                    {
                        ...prevMessages[prevMessages.length - 1],
                        finished: true,
                        speechMode: data.speechMode,
                    },
                ]);
                break;
            default:
                setMessages((prevMessages) => [
                    ...prevMessages,
                    { content: data.content, type: data.type, isUser: false },
                ]);
                break;
        }
    };

    const sendMessage = (
        message,
        show) => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            regCountRef.current += 1; // Increment regCountRef

            const data = JSON.stringify({
                speechMode: show,
                chatid: chatIdRef.current, // Use chatId from useRef
                type: "content",
                repid: `user${regCountRef.current}`, // Use regCountRef for repid
                content: escapeHTML(message).replace(/\n/g, "<br>"),
                objective,
                sources,
            });

            //console.log("Sending message with chatId:", chatIdRef.current);
            wsRef.current.send(data);

            setMessages((prevMessages) => [
                ...prevMessages,
                { content: message, type: "content", isUser: true, speechMode: show },
            ]);
        }
    };

    return {
        messages,
        sendMessage,
        objective,
        problem,
        sources,
        links,
        resetChat,
        chatId: chatIdRef.current, // Expose chatId for external use
    };
}

function escapeHTML(str) {
    return str
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}

export default useWebSocket;
