import React from "react";
import { FaFileAlt, FaFilePdf } from "react-icons/fa"; // Importing both icons

const FileItem = ({ name, url }) => {
    // Determine the icon based on the file extension
    const isTxt = name.endsWith(".txt");
    const iconProps = {
        size: 21,
        color: isTxt ? "#4e4e4e" : "#d9534f", // Neutral grey for TXT, red for PDF
    };

    return (
        <div style={styles.container}>
            <div style={styles.link}>
                {isTxt ? <FaFileAlt {...iconProps} /> : <FaFilePdf {...iconProps} />}
                <span style={styles.text}>{name}</span>
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: "flex",
        alignItems: "center",
        padding: "10px 0",
        borderBottom: "1px solid #ccc",
        minHeight: "50px",
        width: "100%",
    },
    link: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        textDecoration: "none",
        color: "inherit",
    },
    text: {
        marginLeft: "10px",
        fontSize: "14px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        maxWidth: "calc(100% - 31px)", // Ensuring the text does not overlap the icon
    },
};

export default FileItem;
