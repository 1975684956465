import React, { useCallback, useEffect, useRef } from "react";
import "./VolumeRipple.scss";

const VolumeRipple = ({ volume, isBot, isLoading }) => {
    const maxVolume = 50;
    const animationRef = useRef(null);

    const startAnimations = useCallback(() => {
        if (animationRef.current) {
            const scale = volume / maxVolume;
            animationRef.current.style.transform = `scale(${1 + scale})`;
        }
    }, [volume]);

    useEffect(() => {
        startAnimations();
    }, [startAnimations]);

    const containerClass = `container ${isBot ? "bot talking" : ""} ${
        isLoading ? "loading" : ""
    }`;

    return (
        <div className={containerClass}>
            <div ref={animationRef} className="rippler"></div>
            <div className="centerContent">
                <p>
                    {isLoading
                        ? isBot
                            ? "Loading..."
                            : "Listening..."
                        : isBot
                            ? "Talking..."
                            : "Listening..."}
                </p>
            </div>
        </div>
    );
};

export default VolumeRipple;
