import React, { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import Button from "../../components/Button";

function ChatContainer({ messages, sendMessage, show }) {
    const [inputValue, setInputValue] = useState("");
    const messagesEndRef = useRef(null);

    const handleSendMessage = () => {
        if (inputValue.trim() !== "") {
            sendMessage(inputValue, show);
            setInputValue("");
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            handleSendMessage();
        }
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <div id="chat-container">
            <div id="messages">
                {messages.map((
                    msg,
                    index) => (
                    <div
                        key={index}
                        className={msg.isUser ? "user-message" : "server-message"}
                    >
                        {msg.isUser ? "User: " : "Companion: "}
                        <ReactMarkdown>{msg.content}</ReactMarkdown>
                    </div>
                ))}
                <div ref={messagesEndRef}/>
            </div>
            <div className="button-row" id="chat-ui">
                <input
                    type="text"
                    id="message-input"
                    placeholder="Type your message here..."
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <Button onClick={handleSendMessage} text="Send" />
            </div>
        </div>
    );
}

export default ChatContainer;
