import React from "react";
import LinkItem from "../LinkItem";

const LinksList = ({ links }) => {
    return (
        <div style={styles.list}>
            {links.map((
                link,
                index) => (
                <LinkItem key={index} link={link}/>
            ))}
        </div>
    );
};

const styles = {
    list: {
        margin: "10px 0",
    },
};

export default LinksList;
