import React from "react";
import FileList from "../../components/FileList"

function RightContainer({ sources }) {
    const basePath = "/pdfs";

    const fileData = sources.map((
        source: string | number | boolean,
        index: any) => ({
        id: index,
        name: source,
        url: `${basePath}/${encodeURIComponent(source)}`,
    }));

    return (
        <div id="right-container">
            <h3>Sources</h3>
            <FileList files={fileData}/>
        </div>
    );
}

export default RightContainer;
