import React from "react";
import FileItem from "../FileItem";

const FileList = ({ files }) => {
    return (
        <div style={styles.list}>
            {files.map((file) => (
                <FileItem key={file.id} name={file.name} url={file.url}/>
            ))}
        </div>
    );
};

const styles = {
    list: {
        margin: '10px 0',
    },
};

export default FileList;
