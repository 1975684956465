import React from 'react';
import './Button.scss'

type Props = {
    text?: string,
    type?: 'button' | 'submit' | 'reset',
    onClick?: (e: any) => void,
    disabled?: boolean,
    style?: 'primary' | 'secondary',
    icon?: React.ReactNode,
    inverted?: boolean,
};

const Button = ({text, type, onClick, disabled, style, icon, inverted}: Props) => {
    return (
        <button
            className={`button 
            ${style === 'secondary' ? 'button-secondary' : 'button-primary'} 
            ${inverted ? 'inverted' : ''}
            ${!text && icon ? 'button-only-icon' : ''}`
            }
            type={type ? type : 'button'}
            {...(onClick ? {onClick: (e) => onClick(e)} : {})}
            disabled={disabled}
        >
            {icon &&
                <span className={`button-icon ${text ? 'pe-2' : ''}`}>
                    {icon}
                </span>
            }
            {text &&
                <span className="button-text">{text}</span>
            }
        </button>
    );
};

export default Button;

