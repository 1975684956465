import React from 'react';
import './Header.scss';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';

const Header = () => {
    return (
        <div className="w-100 p-4 d-flex justify-content-center align-items-center">
            <Logo/>
        </div>
    );
};

export default Header;
