import React from "react";
import LinksList from "../../components/LinkList";

function LinksContainer({ links }) {

    return (
        <div id="links-container">
            <h3>Links</h3>
            <LinksList links={links}/>
        </div>
    );
}

export default LinksContainer;
