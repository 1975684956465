import React from "react";

function LeftContainer({ objective, problem }) {
    return (
        <div id="left-container">
            <h3>Objective</h3>
            <p id="objective">{objective}</p>
            <h3>Problem</h3>
            <p id="problem">{problem}</p>
            <div id="state_machine"></div>
        </div>
    );
}

export default LeftContainer;
