import React from "react";


interface ControlRowProps {
    resetChat: () => void;
    speechMode: () => void;
    uploadFile: () => void;
    changeLanguage?: any;
}

function ControlRow({ resetChat, speechMode, uploadFile, changeLanguage }: ControlRowProps) {


    return (
        <div className="control-row">
            <button className="reset-button" onClick={resetChat}>
                Reset Chat
            </button>
            <button className="reset-button" onClick={speechMode}>
                Speech Mode
            </button>
            <button className="reset-button" onClick={uploadFile}>
                Upload File
            </button>

        </div>
    );
}

export default ControlRow;
